<template>
    <div class="container-box" v-loading="loading" element-loading-text="加载中...">
        <div class="box-left">
            <div class="search-control">
                <search-control placeholder="请输入院校名称" :value.sync="keyword" @search-click="onSearchClick"></search-control>
            </div>
            <div class="dark-border school-filter">
                <school-filter :filter1.sync="filter1" :filter2.sync="filter2" :filter3.sync="filter3" :filter4.sync="filter4" @filter="onFilterClick"></school-filter>
            </div>
            <div class="dark-border school-list">
                <div v-for="(university, index) in universityInfos">
                    <school-item :title="university.UniversityName" :province="university.UniversityProvince" :characteristic="university.UniversityCharacteristic" :img="schoolImg(university.UniversityCode)" @click="onItemClick(university)"></school-item>
                    <div v-if="index < universityInfos.length - 1" class="bottom-line"></div>
                </div>
            </div>
            <div class="pagination-box">
                <el-pagination background hide-on-single-page :current-page.sync="pageIndex" :page-size.sync="pageSize" :total.sync="totalCount" layout="total, prev, pager, next, jumper" @current-change="onCurrentChange"></el-pagination>
            </div>
        </div>
        <div class="box-right">
            <school-hot></school-hot>
        </div>
    </div>
</template>

<script>
import SchoolFilter from "@/components/PC/SchoolFilter";
import SchoolItem from "@/components/PC/SchoolItem";
import SchoolHot from "@/components/PC/SchoolHot";
import SearchControl from "@/components/PC/SearchControl";
import Config from "@/api/service";

export default {
    name: "PCModule",
    components: {
        SchoolFilter,
        SchoolItem,
        SchoolHot,
        SearchControl,
    },
    data() {
        return {
            loading: true,
            pageIndex: 1,
            pageSize: 10,
            totalCount: 0,
            keyword: '',
            filter1: [],
            filter2: [],
            filter3: [],
            filter4: '',
            universityInfos: [],
        };
    },
    computed: {
        schoolImg() {
            return function (code) {
                return `${Config.school.logo}?SchoolCode=${code}`;
            }
        },
    },
    methods: {
        //获取路由参数
        getRoutParams() {
            this.keyword = this.$route.query.keyword;
        },
        //获取大学列表
        getUniversityInfos() {
            this.loading = true;
            var model = {
                SearchKeyword: this.keyword,
                UniversityProvinces: this.filter1,
                UniversityTeachTypes: this.filter2,
                UniversityCharacteristics: this.filter3,
                UniversitySubject: this.filter4,
                PageIndex: this.pageIndex,
                PageSize: this.pageSize,
            };
            this.$axios
                .post(Config.school.list, model)
                .then((res) => {
                    let data = res.data.Data;
                    this.totalCount = data.TotalCount;
                    this.universityInfos = data.UniversityInfos;
                    this.loading = false;
                })
                .catch((error) => {
                    this.$message.error(error.message);
                });
        },
        //点击大学项
        onItemClick(university) {
            this.$router.push({
                name: "School1",
                params: {
                    proxyCode: this.$store.state.proxyCode,
                    universityCode: university.UniversityCode
                }
            });
        },
        //点击搜索
        onSearchClick() {
            this.pageIndex = 1;
            this.filter1 = '';
            this.filter2 = '';
            this.filter3 = '';
            this.filter4 = '';
            this.getUniversityInfos();
        },
        //点击过滤
        onFilterClick() {
            this.pageIndex = 1;
            this.keyword = '';
            this.getUniversityInfos();
        },
        //点击分页
        onCurrentChange() {
            this.getUniversityInfos();
        }
    },
    mounted() {
        this.getRoutParams();
        this.getUniversityInfos();
    }
};
</script>
<style scoped>
.container-box {
    display: flex;
    flex-direction: row;
}

.box-left {
    flex: 1;
}

.box-right {
    width: 300px;
    margin: 0 0 0 50px;
}

.search-control {
    width: 400px;
    margin: 10px 0;
}

.school-filter {
    padding: 20px;
}

.school-list {
    margin-top: 20px;
    padding: 20px;
}

.dark-border {
    border: 1px solid #e6e6e6;
}

.bottom-line {
    border-top: 1px solid #e6e6e6;
    margin: 10px 0;
}

.pagination-box {
    text-align: center;
    margin: 10px;
}
</style>